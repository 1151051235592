<template>
  <router-view />
</template>

<script>
//import SignIn from './views/SignIn.vue'

export default {
  name: 'App',
  components: {
    //SignIn
  }
}
</script>
