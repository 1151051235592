import { createApp } from 'vue'
import App           from './App.vue'
import router        from './router'
import firebase      from 'firebase/compat/app'
import 'firebase/compat/auth'

/* https://github.com/ankurk91/vue-toast-notification */
import VueToast  from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'

/* https://yobaji.github.io/vue3-google-login */
import vue3GoogleLogin from 'vue3-google-login'

/* https://www.npmjs.com/package/vue3-confirm-dialog */
import Vue3ConfirmDialog from 'vue3-confirm-dialog'
import 'vue3-confirm-dialog/style'

const firebaseConfig = {
    apiKey              : "AIzaSyBmU35vDMmqfac0hJmndkOpzcC4Kp-idPo",
    authDomain          : "qolmonitor.firebaseapp.com",
    projectId           : "qolmonitor",
    storageBucket       : "qolmonitor.appspot.com",
    messagingSenderId   : "202065532022",
    appId               : "1:202065532022:web:bb625912fa06264b563b57",
    measurementId       : "G-6TS64QEDYE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
let gauthClientId = process.env.VUE_APP_GCLIENT

const app = createApp(App)
app.use(vue3GoogleLogin, {
    clientId: gauthClientId
})

app.use(router)
app.use(VueToast)
app.use(Vue3ConfirmDialog)
app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default)
app.mount('#app')